//TODO remove unused CSS
html,
body {
  height: 100%;
  letter-spacing: 0.8px;
}

#__next {
  width: 100%;
  height: 100%;
  @media (min-width: 320px) {
    overflow: hidden;
  }
}

#main-menu {
  .ant-menu-submenu-active,
  .ant-menu-submenu-open {
    color: #ffffff !important;
  }
}

.accounts-dropdown {
  ul {
    padding-top: 4px !important;
    padding-bottom: 4px !important;

    li.ant-menu-item {
      height: 32px;
      line-height: 32px;
      margin-top: 0 !important;
      margin-bottom: 0 !important;

      a:hover {
        color: #04f !important;
      }
    }
  }
}

.languages-dropdown {
  ul {
    min-width: 0 !important;
    padding-top: 4px !important;
    padding-bottom: 4px !important;

    li.ant-menu-item {
      height: 32px;
      line-height: 32px;
      margin-top: 0 !important;
      margin-bottom: 0 !important;

      a:hover {
        color: #04f !important;
      }
    }
  }
}

.page-header {
  padding: 32px 41px !important;

  &-right-bar {
    display: flex;
    align-items: center;
    cursor: pointer;

    &-back-text {
      margin-left: 10px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 28px;
      /* identical to box height, or 175% */
    }
  }

  &-left-bar {
    display: flex;
    align-items: center;

    &-login {
      padding: 10px;
      margin-right: 32px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      cursor: pointer;

      &-bordered {
        padding: 10px;
        margin-right: 32px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        cursor: pointer;
        border-bottom: 3px solid #60fa67;
      }
    }

    &-signup {
      border: 2px solid #60fa67;
      box-sizing: border-box;
      border-radius: 3px;
      padding: 10px;
      font-size: 16px;
      line-height: 24px;
      cursor: pointer;
    }
  }
}

.signup {
  width: 100%;
  height: 100%;

  &-mobile {
    &-big-text {
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 44px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #2a2f39;
    }

    &-small-text {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 28px;
      text-align: center;
      color: #2a2f39;
    }
  }

  &-info {
    background: #2a2f39;

    &-container {
      display: flex;
      flex-direction: column;
      margin-top: 30%;
      background: #2a2f39;
    }

    &-text {
      margin-top: 10%;
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 48px;
      letter-spacing: 0.02em;
      padding: 0px 10%;

      &-white {
        color: white;

        &-small {
          font-size: 16px;
          line-height: 28px;
          color: #ffffff;
        }
      }

      &-green {
        color: #60fa67;
      }
    }
  }

  &-content {
    &-form {
      width: 70%;
      display: flex;
      flex-direction: column;
      height: 90%;
    }

    &-pickup {
      display: flex;
      justify-content: center;
      font-size: 16px;
      line-height: 28px;
      margin-top: 16px;
      flex-direction: column;
      align-items: center;
      width: 100%;

      &-business-choice {
        background: #ffffff;
        border: 1px solid #c4c4c4;
        box-sizing: border-box;
        border-radius: 3px;
        padding: 8px;
        margin-bottom: 15px;
      }
    }

    &-choices {
      margin-top: 20px;
      align-items: center;

      &-choice {
        cursor: pointer;
        border: 1px solid #c4c4c4;
        border-radius: 3px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &-title {
          font-weight: 800;
          text-align: center;

          font-size: 18px;
          line-height: 22px;
          margin-top: 20%;
        }

        &-text {
          text-align: center;

          font-size: 14px;
          line-height: 22px;
        }

        &-business-text {
          text-align: center;
          font-size: 16px;
          line-height: 28px;
        }

        &-active {
          cursor: pointer;
          border: 1px solid #c4c4c4;
          box-sizing: border-box;
          border-radius: 3px;
          background: #ececec;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
    }

    &-title {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      width: 65%;

      &-form {
        margin-top: 20%;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
      }
    }

    &-login {
      margin: 16px 0px;
      text-align: center;
      font-size: 12px;
      line-height: 20px;

      &-form {
        margin: 16px 0px;
        font-size: 12px;
        line-height: 20px;
      }

      &-light {
        font-weight: 200;
      }

      &-bold {
        font-weight: 800;
        cursor: pointer;
      }
    }

    &-continue {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
      background: #60fa67;
      border-radius: 3px;
      height: 48px;
      cursor: pointer;
      width: 78%;
    }
  }

  &-steps {
    row-gap: 0;
    justify-content: center;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    // margin-top: 20px;

    &-step {
      cursor: pointer;
      width: 32px;
      height: 32px;
      margin: 0 8px;
      background: #ececec;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 40px;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }

      &-active {
        cursor: pointer;
        width: 32px;
        margin: 0 8px;
        height: 32px;
        background: #60fa67;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 40px;
        opacity: 0.9;

        &:hover {
          opacity: 1;
          background: #26e830;
        }
      }
    }
  }
}

// ant.design changes
.ant-input-password-icon {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 24px !important;
  /* identical to box height, or 171% */

  display: flex !important;
  align-items: center !important;
  text-align: right !important;
  letter-spacing: 0.08em !important;

  /* green */

  color: #60fa67 !important;
}

.ant-steps {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* color: rgba(0, 0, 0, 0.85); */
  /* font-size: 14px; */
  /* font-variant: tabular-nums; */
  /* line-height: 1.5715; */
  /* list-style: none; */
  /* font-feature-settings: 'tnum', "tnum"; */
  display: flex;
  width: 100%;
  font-size: inherit !important;
  text-align: left;
}

.signup-content-form-title {
  margin-top: 20%;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
}

.add-user-button {
  color: #2a2f39;
  font-weight: 800;
  font-size: 16px;
  border: 3px #26e830 solid;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.continue-button {
  color: #2a2f39;
  font-weight: 800;
  font-size: 16px;
  background: #26e830;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.save-user-button {
  background: #60fa67;
  border-radius: 3px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-width: 84px;
}

.cancel-user-button {
  background: #ececec;
  border-radius: 3px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-width: 84px;
}

.set-user-status {
  font-size: 16px;
}

.mobile-menu {
  width: 100%;
  background: #2a2f39;
  height: 100vh;
  padding: 32px 41px;

  &-item {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
    height: 12%;
    cursor: pointer;

    a {
      color: white;
    }

    &-signup {
      background: #60fa67;
      border: 2px solid #60fa67;
      box-sizing: border-box;
      border-radius: 3px;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      padding: 10px;
    }
  }
}

@keyframes fadeIn {
  0% {
    transform: translateY(-25%);
  }
  50% {
    transform: translateY(4%);
  }
  65% {
    transform: translateY(-2%);
  }
  80% {
    transform: translateY(2%);
  }
  95% {
    transform: translateY(-1%);
  }
  100% {
    transform: translateY(0%);
  }
}

.popup {
  animation-name: fadeIn;
  animation-duration: 0.4s;
  background-clip: padding-box;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  left: 0;
  list-style-type: none;
  margin: 0;
  outline: none;
  padding: 0;
  position: absolute;
  text-align: left;
  top: 0;
  overflow: hidden;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.popup li {
  clear: both;
  color: rgba(0, 0, 0, 0.65);
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  margin: 0;
  padding: 5px 12px;
  transition: all 0.3s;
  white-space: nowrap;
  -webkit-transition: all 0.3s;
}

.popup li:hover {
  background-color: #e6f7ff;
}

.popup li > i {
  margin-right: 8px;
}

.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #ffffff;
  border: 1px solid #d9d9d9 !important;
  border-radius: 0 !important;
  line-height: 25px;
  height: 40px !important;
  width: 300px;
  outline: none;
}

.signup-accounts-item-row {
  /* grey100 */
  align-items: center;
  background: #f8f8f8;
  box-shadow: inset 0px -1px 0px #eaeaea;
  height: 40px;
}

.signup-accounts-item-label-bold {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
}

.signup-content-form {
}

.signup-mobile-users {
  overflow-y: scroll;
}

.signup-mobile-users::-webkit-scrollbar {
  display: none;
}

.ant-table-body::-webkit-scrollbar {
  display: none;
}

.la.la-arrow-down {
  transition: transform 200ms ease;
}

.is-open .la.la-arrow-down {
  transform: rotate(0.5turn);
}

.la.la-arrow-down {
  transition: transform 200ms ease;
  margin-right: 20px;
}

.is-open .la.la-arrow-down {
  transform: rotate(0.5turn);
  margin-right: 20px;
}

.accordion__button:before {
  margin-top: 10px;
  content: "";
  height: 8px;
  width: 8px;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-45deg);
  float: right;
}

.accordion__button[aria-expanded="true"]::before,
.accordion__button[aria-selected="true"]::before {
  transform: rotate(130deg);
  margin-top: 10px;
}

.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding: 0 !important;
  white-space: initial;
  text-align: left;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.button:hover {
  cursor: pointer;
}


.popper__arrow {
  width: 10px;
  height: 10px;
  position: absolute;
}

.popper__arrow::before {
  content: '';
  border-style: solid;
  display: block;
  width: 0;
  z-index: -1;
}

/* Assuming the popper has a white background */
.popper__arrow[data-popper-placement^="top"]::before {
  border-width: 10px 10px 0 10px;
  border-color: white transparent transparent transparent;
  bottom: -10px;
}

.popper__arrow[data-popper-placement^="bottom"]::before {
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent white transparent;
  top: -10px;
}

.popper__arrow[data-popper-placement^="left"]::before {
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent white;
  right: -10px;
}

.popper__arrow[data-popper-placement^="right"]::before {
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent;
  left: -10px;
}


#arrow,
#arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

#arrow {
  visibility: hidden;
}

#arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

#tooltip[data-popper-placement^='top'] > #arrow {
  bottom: -4px;
}

#tooltip[data-popper-placement^='bottom'] > #arrow {
  top: -4px;
}

#tooltip[data-popper-placement^='left'] > #arrow {
  right: -4px;
}

#tooltip[data-popper-placement^='right'] > #arrow {
  left: -4px;
}

// Google maps InfoWindow
.gm-style-iw {
  padding-top: 24px !important;
  .gm-style-iw-chr {
    height: 0;
    button.gm-ui-hover-effect {
      position: absolute !important;
      top: -10px !important;
      right: -10px !important;
    }
  }
}


#main-menu-rc-menu-more-popup {
  background-color: #2a2f39 !important;
  color: white !important;

  .ant-menu-title-content {
    a {
      color: #fff !important
    } 
    a:hover {
      color: #60fa67 !important
    }
    
  }
}

.dashboard-assignee-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}